import Clickable from '/components/Clickable';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';
import { findProductTag } from '../../../utils/product-tags';
import { getCutsFromProductId } from '/services/subscriptions';

import { useEffect, useState } from 'react';

//Valid cuts for print/cut selector
const womensCuts = ['Thongs', 'Cheeky', 'Bikini', 'Boyshort', "Women's Boxer", 'Thongs - Modal'];
const mensCuts = [
	'Boxers',
	'Boxers - Fly',
	'Boxers - Long - Fly',
	'Boxers - Cooling - Fly',
	'Boxers - Brief',
	'Boxers - Trunk',
	'Socks - Ankle',
	'Socks - Crew',
];

const renameByProductType = {
	Boxers: 'No Fly',
	'Boxers - Brief': 'Briefs',
	'Boxers - Cooling - Fly': 'Cooling',
	'Boxers - Cooling - Long': 'Cooling Long',
	'Boxers - Fly': 'w/ Fly',
	'Boxers - Long - Fly': 'Long',
	'Boxers - Trunk': 'Trunks',
	'Socks - Ankle': 'Ankle',
	'Socks - Crew': 'Crew',
	'Athletic Shorts - 5 Inch': '5 Inch',
	'Athletic Shorts - 7 Inch': '7 Inch',
	'Athletic Shorts - 9 Inch': '9 Inch',
	'Thongs - Modal': 'Modal Thong',
	Thongs: 'Seamless Thong',
};

const renameByStyleNumber = {
	10290: 'Lace Cheeky',
	10281: 'Lace Modal Thong',
};

const renameByHandle = {
	'unscented-laundry-detergent-strips': 'Unscented',
	'boulder-breeze-eco-laundry-detergent-strips': 'Boulder Breeze',
};

const getDisplayTitle = (cut) => {
	const styleNumber = findProductTag(cut?.tags, 'style', 1);
	if (cut?.handle?.includes('lace')) {
		if (cut?.handle?.includes('cheeky')) {
			return 'Lace Cheeky';
		}
		return 'Lace Thong';
	}
	return (
		renameByStyleNumber[styleNumber] ||
		renameByProductType[cut?.productType] ||
		renameByHandle[cut?.handle] ||
		cut?.productType
	);
};

const CutIcon = ({ cut, productHandle }) => {
	const displayTitle = getDisplayTitle(cut);

	return (
		<Clickable
			className={classNames(
				' rounded-[4px] text-center font-semibold px-2 uppercase tracking-wider h-[46px] basis-[calc(33%-.5rem)] leading-4 flex justify-center items-center',
				cut.handle == productHandle ? 'bg-gray-800 text-white' : 'bg-gray-200',
			)}
			linkToInternal={'/products/' + cut.handle}
			heapEventName="Cut Click">
			<Typography component="p" variant="heading-xs">
				{displayTitle}
			</Typography>
		</Clickable>
	);
};

const CutIconOnclick = ({ cut, productHandle, setActiveProductHandle }) => {
	const displayTitle = getDisplayTitle(cut);

	return (
		<Clickable
			className={classNames(
				' rounded-[4px] text-center font-semibold px-2 uppercase tracking-wider h-[46px] basis-[calc(33%-.5rem)] leading-4 flex justify-center items-center',
				cut.handle == productHandle ? 'bg-gray-800 text-white' : 'bg-gray-200',
			)}
			onClick={() => setActiveProductHandle(cut.handle)}>
			<Typography component="p" variant="body">
				{displayTitle}
			</Typography>
		</Clickable>
	);
};

const CutSelector = ({ product, setActiveProductHandle }) => {
	const [cuts, setCuts] = useState([]);

	useEffect(() => {
		async function getCuts() {
			const cutsResponse = await getCutsFromProductId(product.id);

			if (!cutsResponse || !Array.isArray(cutsResponse)) {
				setCuts([]);
				return;
			}

			setCuts(cutsResponse);
		}
		getCuts();
	}, [product]);

	if (!cuts || cuts.length === 0 || product.productType == "Men's T-Shirts") {
		return null;
	}

	let validCuts;

	if (womensCuts.includes(product.productType)) {
		validCuts = womensCuts;
	} else if (product?.productType?.includes('Sock')) {
		validCuts = ['Socks - Ankle', 'Socks - Crew'];
	} else {
		validCuts = [
			'Boxers',
			'Boxers - Fly',
			'Boxers - Long - Fly',
			'Boxers - Cooling - Fly',
			'Boxers - Brief',
			'Boxers - Trunk',
			'Long Johns',
			'Athletic Shorts - 5 Inch',
			'Athletic Shorts - 7 Inch',
			'Athletic Shorts - 9 Inch',
			'Laundry Detergent Sheets',
		];
	}

	return (
		<>
			<Typography component="label" variant="heading-xs">
				{product.productType.includes('Laundry') ? 'Scent' : 'Cut'}
			</Typography>
			<div className="flex flex-wrap gap-2 mb-4 my-2">
				{cuts &&
					cuts.map((cut, i) => {
						if (validCuts.includes(cut.productType) && cut.handle) {
							if (setActiveProductHandle) {
								return (
									<CutIconOnclick
										cut={cut}
										key={i}
										productHandle={product.handle}
										setActiveProductHandle={setActiveProductHandle}
									/>
								);
							}
							return <CutIcon cut={cut} key={i} productHandle={product.handle} />;
						}
					})}
			</div>
		</>
	);
};

CutIcon.propTypes = {
	cut: PropTypes.object,
	productHandle: PropTypes.string,
};

CutIconOnclick.propTypes = {
	cut: PropTypes.object,
	productHandle: PropTypes.string,
	setActiveProductHandle: PropTypes.func,
};

CutSelector.propTypes = {
	product: PropTypes.object,
	setActiveProductHandle: PropTypes.func,
};

module.exports = {
	CutSelector,
	mensCuts,
	womensCuts,
};
