import Price from '../Price';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react-lite';
import { useDiscountCodeStore } from '../../state/discount-code-context';
import { useGlobalStore } from '/state/global-context';
import { useEffect, useState } from 'react';

const SubscriptionPrice = ({ priceDefaultSubscription, productType }) => {
	const discountCodeStore = useDiscountCodeStore();
	const [discountSubscriptionPrice, setDiscountSubscriptionPrice] = useState();
	const globalStore = useGlobalStore();
	const promotion = globalStore.getPromotionalConfig();

	useEffect(() => {
		if (promotion?.promotionType == 'subscription-discount') {
			setDiscountSubscriptionPrice(priceDefaultSubscription / 2);
			return;
		}
		if (!discountCodeStore) {
			return;
		}

		const discountCodeConfig = discountCodeStore.getCurrentDiscountCodeConfig(productType);
		setDiscountSubscriptionPrice(discountCodeConfig?.subscriptionPrice);
	}, [discountCodeStore?.currentDiscountCode, productType]);

	return <Price price={discountSubscriptionPrice || priceDefaultSubscription} />;
};

SubscriptionPrice.propTypes = {
	priceDefaultSubscription: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	productType: PropTypes.string,
};

export default observer(SubscriptionPrice);
