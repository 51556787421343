import Image from '/components/Image';
import PropTypes from 'prop-types';
import SizeGuide from '/components/size-guides/SizeGuide';
import Typography from '/components/Typography';
import { useState } from 'react';

const ExpandableSection = ({ title, textComponent, content, isOpen, onToggle }) => {
	return (
		<div className="border-b mb-4 p-4">
			<button className="flex justify-between w-full text-left" onClick={onToggle}>
				<Typography variant="heading-sm" component={textComponent || 'h2'}>
					{title}
				</Typography>
				<span>{isOpen ? '-' : '+'}</span>
			</button>
			{isOpen && <div className="mt-4">{content}</div>}
		</div>
	);
};

const ExpandableDetails = ({
	pdpConfig,
	productDescription,
	productDetails,
	shippingInfo,
	styleNumber,
}) => {
	const [openSection, setOpenSection] = useState(null);

	const handleToggle = (index) => {
		if (openSection === index) {
			setOpenSection(null);
		} else {
			setOpenSection(index);
		}
	};
	const detailsSection = (
		<div>
			{productDetails}
			<SizeGuide styleNumber={styleNumber} />
		</div>
	);

	const guaranteeSection = (
		<p>
			If our socks, underwear or shirts aren&apos;t the best you&apos;ve ever had, your first one is
			100% free (seriously, we just refund you). Terms Apply.
		</p>
	);

	const descriptionSection = <p>{productDescription}</p>;
	const sections = [
		{
			title: 'Fit, Fabric and Features',
			content: detailsSection,
		},
		{
			title: 'Story',
			content: descriptionSection,
		},
		{
			title: "Love 'Em or They're Free Guarantee",
			content: guaranteeSection,
		},
		{
			title: 'Shipping & Free Returns',
			content: shippingInfo,
		},
	];

	return (
		<div className="mt-4">
			<div className="grid grid-cols-2 md:flex items-center gap-1">
				{pdpConfig?.pdpIcons?.map((icon, i) => {
					return (
						<div key={i} className="text-center">
							<div className="flex items-center align-middle justify-center">
								<Image
									src={icon.image}
									alt={icon.text}
									height={50}
									width={50}
									objectFit="contain"
								/>
							</div>
							<p className="!leading-[1.1rem]">{icon.text}</p>
						</div>
					);
				})}
			</div>
			{sections.map((section, index) => (
				<ExpandableSection
					content={section.content}
					textComponent={pdpConfig.detailsComponent}
					isOpen={openSection === index}
					key={index}
					onToggle={() => handleToggle(index)}
					title={section.title}
				/>
			))}
		</div>
	);
};

export default ExpandableDetails;

ExpandableSection.propTypes = {
	content: PropTypes.any,
	isOpen: PropTypes.bool,
	onToggle: PropTypes.func,
	textComponent: PropTypes.string,
	title: PropTypes.string,
};

ExpandableDetails.propTypes = {
	pdpConfig: PropTypes.object,
	productDescription: PropTypes.string,
	productDetails: PropTypes.any,
	shippingInfo: PropTypes.any,
	styleNumber: PropTypes.string,
};
